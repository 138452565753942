/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */

const isObj = require('lodash.get');

export const getSCChannelToken = (state) => isObj(state, 'scChannelToken', {});

export const getProductDetails = (state) => isObj(state, 'productDetails', {});

export const getCartItems = (state) => {
    const cartItems = isObj(state, 'cartItems.cartItems', 0);
    return cartItems;
};

export const getLineItems = (state) => {
    const cartSummary = [];
    const cartItems = isObj(state, 'cartItems.cartItems', false);
    if (cartItems) {
        cartItems.line_items.forEach((item) => {
            if (item.sku_code != null) {
                cartSummary.push(item);
            }
        });
    }
    return cartSummary;
};

export const getSubtotalPrice = (state) => {
    let subTotalAmount = '';
    const cartItems = isObj(state, 'cartItems.cartItems', false);
    if (cartItems) {
        subTotalAmount = cartItems;
    }
    return subTotalAmount;
};

export const getCartItemCount = (state) => {
    let cartItemCount = 0;
    const cartItems = isObj(state, 'cartItems.cartItems', false);

    if (cartItems) {
        cartItems.line_items.forEach((item) => {
            if (item.sku_code != null) {
                cartItemCount += item.quantity;
            }
        });
    }
    return cartItemCount;
};

export const getCountryList = (state) => isObj(state, 'countryList', {});

export const getStateList = (state) => isObj(state, 'stateList', {});

export const getOrderHistory = (state) => isObj(state, 'orders.orders', []);

export const getshippingMethods = (state) => isObj(state, 'shippingMethods', {});

export const getdeliveryLeadTime = (state) => isObj(state, 'deliveryLeadTime', {});

export const getOutOfStockMessage = (state) => isObj(state, 'outOfStockMessage', '');
