<template lang="html">
  <div class="">
    <div v-if="!isLoading">
      <ul class="story-categories list-unstyled" v-if="finalArray.length > 0">
        <!-- <li class="text-center blue-bg-bg" v-for="(shield, index) in finalArray" :key="shield.id"
        > -->
        <li class="text-center" :class="{ 'blue-bg-bg': isPurchased(shield), 'white-bg': !isPurchased(shield) }"
          v-for="(shield, index) in finalArray" :key="shield.id">
          <a v-if="shield.brand === 'lingokids'" class="p-0" @click="lingokids(shield)">
            <ShieldListingItem :shieldData="shield" :shieldSku="priceDetails[index]" />
          </a>
          <a v-else class="p-0" data-bs-toggle="modal" data-bs-target="#product-details-popup"
            @click="shieldClick(shield)">

            <ShieldListingItem :shieldData="shield" :shieldSku="priceDetails" />
          </a>
          <ShieldListingButton :shieldData="shield" :shieldSku="priceDetails[index]" />
        </li>
      </ul>
      <ul v-else>
        <li>{{ $t('noItems') }}</li>
      </ul>
    </div>
    <div v-else>
      <Loader />
    </div>
    <div class="modal fade" id="product-details-popup" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <button type="button" class="btn-close close-blue-btn ms-auto" data-bs-dismiss="modal" aria-label="Close">
            <i class="icon-close" aria-hidden="true"></i>
          </button>

          <DetailsOfCategory v-if="selectedShieldDetails && this.selectedShieldSku" :shield-data="selectedShieldDetails"
            :shield-sku="this.selectedShieldSku" />
          <div v-else>
            <Loader />
          </div>
        </div>
      </div>
    </div>

    <!-- Toggle modals Second -->
    <div class="modal fade" id="shield-play-popup" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2"
      tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" @focusout="stopAudio()">
          <button type="button" class="btn-close close popup" data-bs-target="#product-details-popup"
            data-bs-toggle="modal" @click="stopAudio()">
            <i class="icon-close" aria-hidden="true"></i>
          </button>
          <div v-if="selectedShieldDetails" class="modal-body text-center py-5">
            <h5>{{ selectedShieldDetails?.title }}</h5>
            <div class="audio audioStyle">
              <audio id="audio" controls="controls" class="audioStyle" ref="audio"
                :src="selectedShieldDetails?.sample_audio"></audio>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { useCookies } from 'vue3-cookies';
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import ShieldListingItem from '@/components/partials/ShieldListingItem.vue';
import DetailsOfCategory from '@/components/partials/DetailsOfCategory.vue';
import ShieldListingButton from '@/components/partials/ShieldListingButton.vue';
import Loader from '@/components/commons/Loader.vue';
import CLayerService from '@/services/CLayerService';
import NewContentfulService from '@/services/NewContentfulService';

const cLayerService = new CLayerService();
const newContentfulService = new NewContentfulService();

const { cookies } = useCookies({ secure: true });
export default {
  name: 'ShieldListing',
  props: ['categoryId'],
  components: {
    ShieldListingItem,
    DetailsOfCategory,
    Loader,
    ShieldListingButton,
  },

  setup(props) {
    const langCode = localStorage.getItem('LangCode');
    const store = useStore();
    const router = useRouter();
    const shieldSku = ref({});
    const storyShieldList = ref({});
    const storyShieldSkuList = ref([]);
    const priceDetails = ref([]);
    const isLoading = ref(false);
    const isAdded = ref(false);
    const priceObject = ref({});
    const finalArray = ref([]);
    const customerId = cookies.get('clUserOwnerId');

    if (customerId) {
      store.dispatch('fetchOrderHistory', customerId.owner_id);
    }

    isLoading.value = true;
    // const fetchOrderHistory = async () => {
    //   try {
    //     if (customerId) {
    //       console.log('Customer ID:', customerId);
    //       const response = await store.dispatch('fetchOrderHistory', customerId);
    //       orderHistory.value = response || [];
    //     }
    //   } catch (error) {
    //     console.error('Failed to fetch order history', error);
    //   }
    // };

    if (!props.categoryId) {
      newContentfulService.fetchStory(langCode)
        .then((response) => {
          storyShieldSkuList.value = [];
          priceDetails.value = [];
          storyShieldList.value = response.items;
          storyShieldList.value.forEach((element) => {
            storyShieldSkuList.value.push(element.sku);
          });

          cLayerService.getShieldPriceList(storyShieldSkuList.value)
            .then((prices) => {
              storyShieldList.value.forEach((shields) => {
                prices.forEach((price) => {
                  if (shields.sku === price.code) {
                    priceObject.value = { ...price, ...shields };
                    finalArray.value.push(priceObject.value);
                  }
                });
              });
              isLoading.value = false;
            })
            .catch((error) => {
              console.log('error');
            });
        }).catch((e) => {

        });
    }
    newContentfulService.fetchShielsByGenres(props.categoryId, 5, 0, langCode)
      .then((response) => {
        storyShieldSkuList.value = [];
        priceDetails.value = [];
        storyShieldList.value = response.items;
        storyShieldList.value.forEach((element) => {
          storyShieldSkuList.value.push(element.sku);
        });

        cLayerService.getShieldPriceList(storyShieldSkuList.value)
          .then((prices) => {
            priceObject.value = {};
            storyShieldList.value.forEach((shields) => {
              prices.forEach((price) => {
                if (shields.sku === price.code) {
                  priceObject.value = { ...price, ...shields };
                  finalArray.value.push(priceObject.value);
                }
              });
            });
            isLoading.value = false;
          })
          .catch((error) => {
            console.log('error');
          });
      }).catch((e) => {

      });

    const selectedShieldDetails = computed(() => store.getters.getSelectedShieldDetails);

    const selectedShieldSku = computed(() => store.getters.getSelectedShieldSku);

    const stopAudio = () => {
      document.getElementById('audio').pause();
    };

    const shieldClick = (Data) => {
      store.dispatch('fetchSelectedShieldSku', Data.sku);
      store.dispatch('fetchPlaylistDetail', Data.playlist);
      const details = {
        id: Data.genre,
        locale: langCode,
      };
      store.dispatch('fetchGenresDetail', details);
    };

    const lingokids = (data) => {
      const Data = {
        sku: data.sku,
        locale: langCode,
      };

      store.dispatch('fetchCartItemPrice', data.sku);
      store.dispatch('fetchCartItemDetails', Data);
      router.push({ name: 'shields-details', params: { id: data.sku } });
      // .then(() => { router.go(); })
    };
    const orderHistory = computed(() => store.getters.getOrderHistory);
    const skuList = [...new Set(orderHistory.value
      .flatMap((order) => order.line_items.map((item) => item.sku_code))
      .filter((sku) => sku))];
    // eslint-disable-next-line eqeqeq
    const isPurchased = (shield) => skuList.some((order) => order == shield.sku);

    return {
      shieldSku,
      storyShieldList,
      isAdded,
      isLoading,
      selectedShieldDetails,
      selectedShieldSku,
      shieldClick,
      stopAudio,
      storyShieldSkuList,
      priceDetails,
      langCode,
      lingokids,
      finalArray,
      isPurchased,
    };
  },
};
</script>

<style scoped lang="scss"></style>
