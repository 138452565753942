<template>
      <div class="order-item mb-4" aria-label="order summery">
      <h6 class="text-center">{{ $t('Coupon') }}</h6>
      <hr />
      <div v-if="!cartItems?.coupon_code" class="input-button-container">
        <input
          v-model="couponCode"
          type="text"
          class="form-control"
          :placeholder="$t('Enter Coupon Code')"
          style="width: 100%;"
          :disabled="couponApplied"
        />
        <div class="button-container">
            <button
        v-if="!couponApplied"
          class="blob-btn fill-bg btn-lg apply-btn ml-4"
          @click="applyCoupon"
          style="white-space: nowrap; margin-top: 5px;"
        >
          {{ $t('Apply') }}
          <span class="blob-btn__inner">
            <span class="blob-btn__blobs">
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
            </span>
          </span>
        </button>
      </div>

      <!-- Success & Error Messages -->
      <div v-if="successMessage" class="success-message">
        {{ successMessage }}
      </div>
      <div v-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </div>
    </div>
    <div  v-else>
    <h6 class="coupon-text" style="color: green;">
    {{ $t('Coupon Code:') }} {{ cartItems?.coupon_code }}<br>
    {{ $t('Discount Amount:') }} {{ Math.abs(cartItems.discount_amount_float) }}
    </h6>
    <button
        class="blob-btn fill-bg btn-lg apply-btn ml-4 center-btn center-btn"
          @click="removeCoupon"
          style="white-space: nowrap; margin-top: 5px;"
        >
          {{ $t('Remove') }}
          <span class="blob-btn__inner">
            <span class="blob-btn__blobs">
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
            </span>
          </span>
      </button>
    </div>
    </div>
  </template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useCookies } from 'vue3-cookies';
import CLayerService from '@/services/CLayerService';

const { cookies } = useCookies({ secure: true });
const clayerService = new CLayerService();

export default {
  name: 'NewCoupon',
  setup() {
    const store = useStore();
    const router = useRouter();
    const couponCode = ref('');
    const successMessage = ref('');
    const errorMessage = ref('');
    const couponApplied = ref(false);
    const { orderId } = cookies.get('orderIdCookie') || {};

    const checkout = () => {
      router.push('/checkout');
    };
    const cartItems = computed(() => store.getters.getCartItems);
    console.log(cartItems.value?.coupon_code);

    const applyCoupon = async () => {
      try {
        const response = await clayerService.applyCoupon(orderId, couponCode.value);
        store.dispatch('fetchCartItems', orderId);

        successMessage.value = 'Coupon applied successfully!';
        errorMessage.value = '';
      } catch (error) {
        successMessage.value = '';
        errorMessage.value = 'Failed to apply coupon. Please try again.';
      }
    };

    const removeCoupon = async () => {
      try {
        await clayerService.removeCoupon(orderId);
        store.dispatch('fetchCartItems', orderId);

        successMessage.value = 'Coupon removed successfully!';
        errorMessage.value = '';
      } catch (error) {
        successMessage.value = '';
        errorMessage.value = 'Failed to remove coupon. Please try again.';
      }
    };

    return {
      couponCode, checkout, removeCoupon, applyCoupon, successMessage, errorMessage, couponApplied, cartItems,
    };
  },
};

</script>

  <style scoped>

.input-container input {
  width: 100%;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px; /* Space between Apply & Remove buttons */
}

  .success-message {
    color: green;
    margin-top: 10px;
  }

  .error-message {
    color: red;
    margin-top: 10px;
  }

.coupon-text {
  font-size: 14px;
  text-align: center;
}

.center-btn {
  display: block;
  margin: 0 auto;  /* Center the button */
}
</style>
