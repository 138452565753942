<template lang="html">
  <div v-if="cartItems" class="order-item" aria-label="order summery">
    <div class="text-center">
      <h6>{{ $t('orderSum') }}</h6>
      <hr />
    </div>
    <div class="row mb-3">
      <div class="col">
        <h6 class="">{{ $t('Subtotal') }} :</h6>
      </div>
      <div class="col">
        <p>
          {{ price(cartItems.currency_code, cartItems.subtotal_amount_float) }}
        </p>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <h6 class="">{{ $t('Shipping') }} :</h6>
      </div>
      <div class="col">
        <p>
          {{ price(cartItems.currency_code, cartItems.shipping_amount_float) }}
        </p>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <h6 class="">{{ $t('Discount') }} :</h6>
      </div>
      <div class="col">
        <p>
          {{ price(cartItems.currency_code, Math.abs(cartItems.discount_amount_float)) }}
        </p>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <h6 class="">{{ $t('Tax') }} :</h6>
      </div>
      <div class="col">
        <p>
          {{ price(cartItems.currency_code, cartItems.total_tax_amount_float) }}
        </p>
      </div>
    </div>

    <hr class="mt-4" />
    <div class="row order-total-price">
      <div class="col">
        <h5>{{ $t('totalCost') }} :</h5>
      </div>
      <div class="col">
        <p>
          {{ price(cartItems.currency_code, cartItems.total_amount_with_taxes_float) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useCookies } from 'vue3-cookies';
import { computed, ref } from 'vue';
import CLayerService from '@/services/CLayerService';

const { cookies } = useCookies({ secure: true });
const clayerService = new CLayerService();

export default {
  name: 'OrderSummery',
  props: ['cartData'],
  setup() {
    const store = useStore();
    const router = useRouter();
    const couponCode = ref('');
    const successMessage = ref('');
    const errorMessage = ref('');
    const { orderId } = cookies.get('orderIdCookie') || {};
    store.dispatch('fetchCartItems', orderId);

    const checkout = () => {
      router.push('/checkout');
    };

    const price = (currencyCode, value) => {
      const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currencyCode,
      });
      return formatter.format(value);
    };

    const cartItems = computed(() => store.getters.getCartItems);
    console.log(cartItems.value?.coupon_code);

    const applyCoupon = async () => {
      console.log('Order ID:', orderId, 'Coupon Code:', couponCode.value);

      try {
        const response = await clayerService.applyCoupon(orderId, couponCode.value);
        console.log('API call completed. Received response:', response);
        store.dispatch('fetchCartItems', orderId);

        successMessage.value = 'Coupon applied successfully!';
        console.log(successMessage.value);

        // eslint-disable-next-line no-alert
      } catch (error) {
        console.log(error);
        successMessage.value = '';
        errorMessage.value = 'Failed to apply coupon. Please try again.';
      }
    };

    return {
      cartItems, checkout, price, couponCode, applyCoupon, successMessage,
    };
  },
};
</script>

<style scoped lang="scss"></style>
