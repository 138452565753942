/* eslint-disable */
import CLayerBaseService from '@/services/CLayerBaseService';
import CLayerService from '@/services/CLayerService';
import { useCookies } from 'vue3-cookies';

const axios = require('axios');

const cLayerBaseService = new CLayerBaseService();
const cLayerService = new CLayerService();

const { cookies } = useCookies({ secure: true });

export default class BaseService {
  constructor() {
    this.baseURL = process.env.VUE_APP_API_URL;
    this.http = axios;
    this.guestToken = process.env.VUE_APP_GUEST_API_TOKEN;
    this.authTokenRequest = null; // Queuing authentication request

    axios.interceptors.response.use(
      (res) => res,
      async (err) => {
        const originalConfig = err.config;
        // eslint-disable-next-line no-underscore-dangle
        if (originalConfig.url === `${this.baseURL}v1/auth/jwt-verify/` && !originalConfig._retry) {
          originalConfig._retry = true;
          this.logout();
        } else if (err.response) {
          if (
            err.response.status === 401 &&
            err.response.data.message === 'Unauthorized' &&
            !originalConfig._retry
          ) {
            originalConfig._retry = true;
            try {
              const rs = await this.getAuthToken();
              const { accessToken } = rs.data;
              cookies.set('storyPhoneUserAuthCookie', {
                access_token: accessToken,
              });
              cookies.set('storyPhoneUserRefreshCookie', {
                refresh_token: rs.data.refreshToken,
              });
              originalConfig.headers.Authorization = `Bearer ${this.getUserToken()}`;
              return axios.request(originalConfig);
            } catch (error) {
              return Promise.reject(error);
            }
          }
        }

        return Promise.reject(err);
      }
    );
  }

  refresh(credentials) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/auth/jwt-verify/`;
      this.http
        .post(apiEndPoint, credentials)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.data.errors);
        });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getUserToken = () => {
    const token = cookies.get('storyPhoneUserAuthCookie');
    return token.access_token;
  };

  logout = () => {
    localStorage.removeItem('storyPhoneUser');
    localStorage.removeItem('cLayerUserToken');
    cookies.remove('clUserAuthCookie');
    cookies.remove('storyPhoneUserAuthCookie');
    cookies.remove('orderIdCookie');
    cookies.remove('clUserOwnerId');
    cookies.remove('clAuthCookie');
    cookies.remove('orderIdCookie');
    cookies.remove('clUserAuthRefreshCookie');
    cookies.remove('storyPhoneUserRefreshCookie');
    cLayerBaseService
      .createToken()
      .then(() => {
        cLayerService
          .createOrderService()
          .then((createdOrder) => {
            window.location.pathname = '/login';
            cookies.set('orderIdCookie', {
              orderId: createdOrder.id,
            });
            // store.dispatch('fetchCartItems', createdOrder.id);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * This function makes a call to get the auth token
   * or it returns the same promise as an in-progress call to get the auth token */
  getAuthToken = () => {
    if (!this.authTokenRequest) {
      this.authTokenRequest = this.makeActualAuthenticationRequest()
        .then((response) => {
          this.resetAuthTokenRequest();
          return response; // Ensure callers get the resolved value
        })
        .catch((error) => {
          this.resetAuthTokenRequest();
          return Promise.reject(error); // Ensure callers get the rejected error
        });
    }
    return this.authTokenRequest;
  };

  makeActualAuthenticationRequest = async () => {
    const refreshToken = cookies.get('storyPhoneUserRefreshCookie');
    const rs = await axios.post(`${this.baseURL}v1/auth/jwt-verify/`, {
      refreshToken: refreshToken.refresh_token,
    });
    if (rs) {
      cLayerBaseService.refreshToken();
    }
    return rs;
  };

  resetAuthTokenRequest = () => {
    this.authTokenRequest = null;
  };
}
